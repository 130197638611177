import { produce } from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { MaxLength, Role } from '../../../context-providers/enums/global-enums';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';

import { IUserCreate } from '../../../context-providers/users/users-reducer';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import { Box } from '../../atoms/custom-elements/Box.style';
import Radio from '../../atoms/form-fields/radio/Radio';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { IconCheck, IconPlus } from '../../icons';
import {
  ModalText,
  RadioWrapper,
  CreateUserHeader,
  CreateUserSubtitle,
  CreateUserSmallTitle,
} from '../ModalContent.style';
import Select, { IOption } from '../../atoms/form-fields/select/Select';
import { ILearnerCentre } from '../../../utils/exportLearnerHelper';

interface IConfirmCreateUser {
  onConfirmCreateUser: () => void;
  centres: ILearnerCentre[];
}

const CreateUser = ({ onConfirmCreateUser, centres }: IConfirmCreateUser) => {
  const emptyUser: IUserCreate = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    oneFileCentreId: centres.length === 1 ? centres[0].id : 0,
  };

  const [page, setPage] = useState(1);
  const closeModal = useCloseModal();
  const [emailErr, setEmailErr] = useState('');
  const [isEmailInUse, setIsEmailInUse] = useState(false);
  const [createdUser, setCreatedUser] = useState(emptyUser);
  const [submitted, setSubmitted] = useState(false);
  const [centresDisabled, setCentresDisabled] = useState(true);

  const centreOptions: IOption[] = centres.map((i) => ({
    value: i.id.toString(),
    label: i.name,
  }));

  const currentCentreOption: IOption | undefined = centreOptions.find(
    (c) => c.value === createdUser.oneFileCentreId?.toString(),
  );

  const hasCentres: boolean = centres.length > 0;

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!submitted) {
      setSubmitted(true);
      // send form data to api
      const res = await fetchAPI<string>({
        path: 'users/create',
        body: createdUser,
        method: 'POST',
        invokeNotificationOnError: false,
      });

      if (res.success) {
        setPage(2);
      } else {
        setIsEmailInUse(true);
        setSubmitted(false);
      }
    }
  };

  useEffect(() => {
    const checkEmailErr = validateField(createdUser.emailAddress, {
      required: true,
      email: true,
      maxLength: MaxLength.EmailAddress,
      isEmailInUse,
    });
    setEmailErr(checkEmailErr);
  }, [createdUser.emailAddress, isEmailInUse]);

  const handleChange = (value: string, fieldName: string) => {
    if (fieldName === 'emailAddress') {
      setIsEmailInUse(false);
    }
    setCreatedUser(
      produce(createdUser, (newCreatedUser) => {
        set(newCreatedUser, fieldName, value);
        return newCreatedUser;
      }),
    );
  };
  const handleRoleChange = (value: Role, fieldName: string) => {
    setCentresDisabled(value !== Role.Tutor);
    setCreatedUser(
      produce(createdUser, (newCreatedUser) => {
        set(newCreatedUser, fieldName, value);
        return newCreatedUser;
      }),
    );
  };

  const onKeyDownSelect = (e: any) => {
    if (e.key === 'Enter') {
      setPage(2);
    }
  };

  const onClose = (): void => {
    onConfirmCreateUser();
    closeModal();
  };

  // field errors
  const firstNameErr = validateField(createdUser.firstName, {
    required: true,
    firstName: true,
    maxLength: 50,
  });
  const lastNameErr = validateField(createdUser.lastName, {
    required: true,
    lastName: true,
    maxLength: 50,
  });

  const isValidRole = (role: Role | undefined) => {
    if (!role) {
      return false;
    }

    if (role !== Role.SuperAdmin && role !== Role.Tutor && role != Role.Admin) {
      return false;
    }

    return true;
  };

  const isValidCentre = () => {
    if (createdUser.role !== Role.Tutor || !hasCentres) return '';

    if (createdUser.oneFileCentreId === 0)
      return 'Please select a centre for this tutor';

    return '';
  };

  const userTypeErr = !isValidRole(createdUser.role);

  const centerErr = isValidCentre();

  const isValid =
    !firstNameErr && !lastNameErr && !emailErr && !userTypeErr && !centerErr;

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
    >
      {page === 1 ? (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <CreateUserHeader>
              <IconPlus width={25} height={25} />
              <h2>Create New User</h2>
            </CreateUserHeader>
          </Stack>
          <Stack stackMultiplier={0.5}>
            <TextInput
              label="First name"
              value={createdUser.firstName}
              id="firstName"
              error={firstNameErr}
              placeholder="First Name *"
              onChangeText={(str) => handleChange(str, 'firstName')}
            />
            <TextInput
              label="Surname"
              value={createdUser.lastName}
              id="surname"
              placeholder="Surname *"
              error={lastNameErr}
              onChangeText={(str) => handleChange(str, 'lastName')}
            />
            <TextInput
              label="Email address"
              value={createdUser.emailAddress}
              id="emailAddress"
              maxLength={MaxLength.EmailAddress}
              placeholder="Email Address *"
              error={emailErr}
              onChangeText={(str) => handleChange(str, 'emailAddress')}
            />
          </Stack>
          <Stack stackMultiplier={0.5}>
            <RadioWrapper className="column">
              <CreateUserSubtitle>Assign Role</CreateUserSubtitle>
              <Radio
                key="super-user"
                id="super-user"
                inline
                label="Super Admin"
                dataQa="setModal-radio-super-user"
                checked={createdUser.role === Role.SuperAdmin}
                onChange={() => {
                  handleRoleChange(Role.SuperAdmin, 'role');
                }}
              />
              <CreateUserSmallTitle>
                This role can view and manage all features within Enrol
              </CreateUserSmallTitle>
              <Radio
                key="admin-user"
                id="admin-user"
                inline
                label="Admin"
                dataQa="setModal-radio-admin-user"
                checked={createdUser.role === Role.Admin}
                onChange={() => {
                  handleRoleChange(Role.Admin, 'role');
                }}
              />
              <CreateUserSmallTitle>
                This role can manage Learners and Employers, and send and review
                forms
              </CreateUserSmallTitle>
              <Radio
                key="tutor-user"
                id="tutor-user"
                inline
                label="Tutor"
                dataQa="setModal-radio-tutor-user"
                checked={createdUser.role === Role.Tutor}
                onChange={() => {
                  handleRoleChange(Role.Tutor, 'role');
                }}
              />
              <CreateUserSmallTitle>
                This role can manage Employers, view Learners and Courses, and
                Send and Review Forms
                {hasCentres && (
                  <Select
                    id="centre-select"
                    dataQa="centre-select"
                    placeholder="Select centre"
                    options={centreOptions}
                    initalValue={currentCentreOption}
                    disabled={centresDisabled}
                    error={centerErr}
                    onOptionChanged={(centerOption) =>
                      handleChange(centerOption.value, 'oneFileCentreId')
                    }
                  />
                )}
              </CreateUserSmallTitle>
            </RadioWrapper>
          </Stack>

          <ButtonRow>
            <Button onClick={closeModal} variant="subtle">
              Cancel
            </Button>
            <Button
              iconLeft={<IconPlus width={15} height={15} />}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              disabled={!isValid}
              onKeyDown={(e) => {
                onKeyDownSelect(e);
              }}
            >
              Create User
            </Button>
          </ButtonRow>
        </Stack>
      ) : (
        ''
      )}
      {page === 2 ? (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <Box textAlign="center">
              <IconCheck />

              <br />
              <br />
              <h2>New User Invite Sent</h2>
            </Box>
          </Stack>
          <Box textAlign="center">
            <ModalText>
              You’ve successfully sent an invite to join Enrol!
              <br />
              <br />
              An e-mail invite has been sent and once accepted, the new user
              will be added to Enrol.
            </ModalText>
          </Box>
          <ButtonRow>
            <Button onClick={onClose}>Close</Button>
          </ButtonRow>
        </Stack>
      ) : (
        ''
      )}
    </form>
  );
};

export default CreateUser;
