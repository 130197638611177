import React from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import AuthWrapper from '../../components/layout/AuthWrapper';
import UsersList from '../../components/organisms/users-list/UsersList';

const ListUsersPage = () => {
  return (
    <AuthWrapper>
      <Head title="Users" />
      <Layout
        section="users"
        breadcrumbs={[{ link: '/', label: 'Home' }, { label: 'Users' }]}
      >
        <UsersList />
      </Layout>
    </AuthWrapper>
  );
};

export default ListUsersPage;
