import { produce } from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { MaxLength, Role } from '../../../context-providers/enums/global-enums';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';

import { IUser } from '../../../context-providers/users/users-types';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Radio from '../../atoms/form-fields/radio/Radio';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import {
  IconChangeStatus,
  IconEdit,
  IconSwitch,
  IconWarning,
} from '../../icons';
import {
  RadioWrapper,
  CreateUserHeader,
  CreateUserSubtitle,
  CreateUserSmallTitle,
} from '../ModalContent.style';
import FilterSingleItem from '../../atoms/filter/FilterSingleItem';
import { FilterItem } from '../../atoms/filter/models/filter-item';
import { IUserInList } from '../../../context-providers/user/user-reducer';
import { ILearnerCentre } from '../../../utils/exportLearnerHelper';
import Select, { IOption } from '../../atoms/form-fields/select/Select';

interface IConfirmEditUser {
  editUser: IUser;
  onComplete: () => void;
  centres: ILearnerCentre[];
}

const EditUser = ({ editUser, onComplete, centres }: IConfirmEditUser) => {
  const closeModal = useCloseModal();
  const [editedUser, setEditedUser] = useState<IUser>(editUser);
  const [submitted, setSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const [oldRole, setOldRole] = useState<Role>();
  const [learnerCount, setLearnerCount] = useState<number>(0);
  const [hasAlternate, setHasAlternate] = useState<boolean>(false);
  const [admins, setAdmins] = useState<IUserInList[]>([]);
  const [tutors, setTutors] = useState<IUserInList[]>([]);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = (isMenuOpen: boolean): void => {
    setIsMenuOpen(isMenuOpen);
  };

  const [centresDisabled, setCentresDisabled] = useState(
    editUser.role !== Role.Tutor,
  );

  const centreOptions: IOption[] = centres.map((i) => ({
    value: i.id.toString(),
    label: i.name,
  }));

  const currentCentreOption: IOption | undefined = centreOptions.find(
    (c) => c.value === editedUser.oneFileCentreId?.toString(),
  );
  const hasCentres: boolean = centres.length > 0;

  const loadLearners = useCallback(async () => {
    setOldRole(editUser.role);

    if (editUser.role === Role.Admin || editUser.role === Role.Tutor) {
      const res = await fetchAPI<string[]>({
        path: `users/learnercount/${editUser.id}`,
        method: 'GET',
      });

      if (res.success) {
        setLearnerCount(Number(res.body));
      }
    }
  }, []);

  const loadAdmins = useCallback(async () => {
    if (editUser.role === Role.Admin) {
      const res = await fetchAPI<IUserInList[]>({
        path: `users/getadmins/${editUser.id}`,
        method: 'GET',
      });

      if (res.success) {
        setAdmins(res.body);
      }
    }
  }, []);
  const adminOptions: FilterItem[] = admins.map((i) => ({
    id: i.id,
    name: i.name,
  }));

  const loadTutors = useCallback(async () => {
    if (editUser.role === Role.Tutor) {
      const res = await fetchAPI<IUserInList[]>({
        path: `users/gettutors/${editUser.id}`,
        method: 'GET',
      });

      if (res.success) {
        setTutors(res.body);
      }
    }
  }, []);
  const tutorOptions: FilterItem[] = tutors.map((i) => ({
    id: i.id,
    name: i.name,
  }));

  useEffect(() => {
    loadLearners();
    loadAdmins();
    loadTutors();
  }, [loadLearners, loadAdmins, loadTutors]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (
      (oldRole === Role.Admin && learnerCount > 0 && admins.length < 1) ||
      (oldRole === Role.Tutor && learnerCount > 0 && tutors.length < 1)
    ) {
      setStep(1);
    } else if (
      (oldRole === Role.Admin && learnerCount > 0 && admins.length > 0) ||
      (oldRole === Role.Tutor && learnerCount > 0 && tutors.length > 0)
    ) {
      setStep(2);
    } else {
      submitChanges(event);
    }
  };

  const submitChanges = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!submitted) {
      setSubmitted(true);

      const res = await fetchAPI<string>({
        path: 'users/edit/${editUser.id}',
        body: editedUser,
        method: 'POST',
      });

      if (res.success) {
        onComplete && onComplete();
        closeModal();
      }
    }
  };

  const handleChange = (value: string, fieldName: string) => {
    setEditedUser(
      produce(editedUser, (newEditedUser) => {
        set(newEditedUser, fieldName, value);
        return newEditedUser;
      }),
    );
  };

  const handleAlternateChanged = (selectedItem: FilterItem): void => {
    setHasAlternate(true);

    setEditedUser(
      produce(editedUser, (newEditedUser) => {
        set(newEditedUser, 'alternateUserId', Number(selectedItem.id));
        return newEditedUser;
      }),
    );
  };

  const handleRoleChange = (value: Role, fieldName: string) => {
    setCentresDisabled(value !== Role.Tutor);
    setEditedUser(
      produce(editedUser, (newEditedUser) => {
        set(newEditedUser, fieldName, value);
        return newEditedUser;
      }),
    );
  };

  // field errors
  const firstNameErr = validateField(editedUser.firstName, {
    required: true,
    firstName: true,
    maxLength: 50,
  });

  const lastNameErr = validateField(editedUser.lastName, {
    required: true,
    lastName: true,
    maxLength: 50,
  });

  const isValidRole = (role: Role | undefined) => {
    if (!role) {
      return false;
    }

    if (role !== Role.SuperAdmin && role !== Role.Tutor && role != Role.Admin) {
      return false;
    }

    return true;
  };

  const isValidCentre = () => {
    if (editedUser.role !== Role.Tutor || !hasCentres) return '';

    if (editedUser.oneFileCentreId === 0)
      return 'Please select a centre for this tutor';

    return '';
  };

  const userTypeErr = !isValidRole(editedUser.role);

  const centerErr = isValidCentre();

  const isValid = !firstNameErr && !lastNameErr && !userTypeErr;

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
      style={isMenuOpen ? { maxWidth: 412, height: 550 } : { maxWidth: 412 }}
    >
      {step === 0 ? (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <CreateUserHeader>
              <IconEdit width={25} height={25} />
              <h2>Edit User</h2>
            </CreateUserHeader>
          </Stack>
          <Stack stackMultiplier={0.5}>
            <TextInput
              label="First name"
              value={editedUser.firstName}
              id="firstName"
              error={firstNameErr}
              placeholder="First Name *"
              onChangeText={(str) => handleChange(str, 'firstName')}
            />
            <TextInput
              label="Surname"
              value={editedUser.lastName}
              id="surname"
              placeholder="Surname *"
              error={lastNameErr}
              onChangeText={(str) => handleChange(str, 'lastName')}
            />
            <TextInput
              label="Email address"
              value={editedUser.email}
              id="emailAddress"
              maxLength={MaxLength.EmailAddress}
              placeholder="Email Address *"
              onChangeText={(str) => handleChange(str, 'emailAddress')}
              disabled={true}
            />
          </Stack>
          <Stack stackMultiplier={0.5}>
            <RadioWrapper className="column">
              <CreateUserSubtitle>Assign Role</CreateUserSubtitle>
              <Radio
                key="super-user"
                id="super-user"
                inline
                label="Super Admin"
                dataQa="setModal-radio-super-user"
                checked={editedUser.role === Role.SuperAdmin}
                onChange={() => {
                  handleRoleChange(Role.SuperAdmin, 'role');
                }}
              />
              <CreateUserSmallTitle>
                This role can view and manage all features within Enrol
              </CreateUserSmallTitle>
              <Radio
                key="admin-user"
                id="admin-user"
                inline
                label="Admin"
                dataQa="setModal-radio-admin-user"
                checked={editedUser.role === Role.Admin}
                onChange={() => {
                  handleRoleChange(Role.Admin, 'role');
                }}
              />
              <CreateUserSmallTitle>
                This role can manage Learners and Employers, and send and review
                forms
              </CreateUserSmallTitle>
              <Radio
                key="tutor-user"
                id="tutor-user"
                inline
                label="Tutor"
                dataQa="setModal-radio-tutor-user"
                checked={editedUser.role === Role.Tutor}
                onChange={() => {
                  handleRoleChange(Role.Tutor, 'role');
                }}
              />
              <CreateUserSmallTitle>
                This role can manage Employers, view Learners and Courses, and
                Send and Review Forms
                {hasCentres && (
                  <Select
                    id="centre-select"
                    dataQa="centre-select"
                    placeholder="Select centre"
                    options={centreOptions}
                    initalValue={currentCentreOption}
                    disabled={centresDisabled}
                    error={centerErr}
                    onOptionChanged={(centerOption) =>
                      handleChange(centerOption.value, 'oneFileCentreId')
                    }
                  />
                )}
              </CreateUserSmallTitle>
            </RadioWrapper>
          </Stack>

          <ButtonRow>
            <Button onClick={closeModal} variant="subtle">
              Cancel
            </Button>
            <Button
              iconLeft={<IconEdit width={15} height={15} />}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              disabled={!isValid}
            >
              Edit User
            </Button>
          </ButtonRow>
        </Stack>
      ) : step === 1 ? (
        <Stack stackMultiplier={2}>
          <CreateUserHeader>
            <IconChangeStatus width={25} height={25} />
            <h2>Reassign Role</h2>
          </CreateUserHeader>

          <Stack>
            <div
              style={{
                color: '#96051F',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <p>
                <IconWarning></IconWarning>
              </p>
              <p style={{ marginLeft: 7 }}>
                {editUser.firstName} {editUser.lastName} was assigned{' '}
                {learnerCount} learners that no longer have{' '}
                {editUser.role === Role.Admin && 'an admin'}
                {editUser.role === Role.Tutor && 'a tutor'} assigned to them.
                There are no other {editUser.role === Role.Admin && 'admins'}{' '}
                {editUser.role === Role.Tutor && 'tutors'} to reassign these
                learners to.
              </p>
            </div>
          </Stack>

          <ButtonRow>
            <Button
              onClick={() => {
                setStep(0);
              }}
              variant="subtle"
            >
              Back
            </Button>
            <Button
              iconLeft={<IconChangeStatus width={15} height={15} />}
              onClick={(e) => {
                e.preventDefault();
                submitChanges(e);
              }}
              disabled={!isValid}
            >
              Reassign role
            </Button>
          </ButtonRow>
        </Stack>
      ) : step === 2 ? (
        <Stack stackMultiplier={2}>
          <CreateUserHeader>
            <IconSwitch width={25} height={25} />
            <h2>Reassign Learners</h2>
          </CreateUserHeader>

          {editUser.role === Role.Admin && (
            <Stack>
              <p>Reassign learners to:</p>
              <FilterSingleItem
                filterName="admin-filter"
                items={adminOptions}
                title="Admin"
                searchTitle="Admin"
                onItemChanged={handleAlternateChanged}
                toggleMenu={toggleMenu}
              />
            </Stack>
          )}

          {editUser.role === Role.Tutor && (
            <Stack>
              <p>Reassign learners to:</p>
              <FilterSingleItem
                filterName="tutor-filter"
                items={tutorOptions}
                title="Tutor"
                searchTitle="Tutor"
                onItemChanged={handleAlternateChanged}
                toggleMenu={toggleMenu}
              />
            </Stack>
          )}

          <ButtonRow>
            <Button onClick={closeModal} variant="subtle">
              Cancel
            </Button>
            <Button
              iconLeft={<IconSwitch width={15} height={15} />}
              onClick={(e) => {
                e.preventDefault();
                submitChanges(e);
              }}
              disabled={!hasAlternate}
            >
              Reassign learners
            </Button>
          </ButtonRow>
        </Stack>
      ) : null}
    </form>
  );
};

export default EditUser;
