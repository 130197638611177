import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Permission } from '../../../context-providers/enums/global-enums';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { IUser } from '../../../context-providers/users/users-types';
import { fetchAPI } from '../../../utils/fetch-api';
import { getUserClaims } from '../../../utils/jwt-decode';
import { usePagedRequest } from '../../../utils/paged-request';
import Button from '../../atoms/button/Button';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import Paging from '../../atoms/paging/Paging';
import Stack from '../../atoms/stack/Stack';
import Table from '../../atoms/table/Table';
import {
  SingleLineBoldCell,
  TableActions,
} from '../../atoms/table/Table.style';
import { IconDelete, IconEdit } from '../../icons';
import { IconPlus } from '../../icons/IconPlus';
import { FullWidthContainer } from '../../layout/Layout.style';
import { FlexGrid } from '../../atoms/custom-elements/Flex.style';
import UserNameFilter from '../../atoms/filter/filter-types/user-name-filter';
import UserRoleFilter from '../../atoms/filter/filter-types/user-role-filter';
import ActionLink from '../../atoms/custom-elements/linkaction.style';
import { deleteQueryParams, getAllQueryVar } from '../../../utils/query-vars';
import ExportLearnerHelper, {
  ILearnerCentre,
} from '../../../utils/exportLearnerHelper';
import DeleteData from '../../modals/forms/DeleteData';
import CreateUser from '../../modals/users/CreateUser';
import EditUser from '../../modals/users/EditUser';

interface IDeleteUser {
  id: string;
  email: string;
}

const UsersList = () => {
  const showModal = useShowModal();

  const user = getUserClaims();
  let isSuperAdmin: boolean = false;

  if (user) {
    isSuperAdmin = userHasPermission(user.permissions, Permission.CreateForm);
  }

  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };

  const [centres, setCentres] = useState<ILearnerCentre[]>([]);
  const [loadingCentres, setLoadingCentres] = useState<boolean>(false);
  useEffect(() => {
    setLoadingCentres(true);
    ExportLearnerHelper.getCentres()
      .then((response) => {
        setCentres(response);
      })
      .finally(() => setLoadingCentres(false));
  }, []);

  const usersParams = extractQueryVars('users');
  const userRolesParams = extractQueryVars('userroles');

  const hasActiveFilters = usersParams.length > 0 || userRolesParams.length > 0;

  const users = usePagedRequest<IUser>('Users', {
    sortColumnName: 'lastModified',
    users: usersParams.join(','),
    userRoles: userRolesParams.join(','),
  });

  const renderRow = (data: IUser) => [
    <SingleLineBoldCell key="1" data-qa="usersTable-name-lbl">
      {data.name}
    </SingleLineBoldCell>,
    <SingleLineBoldCell key="2" data-qa="usersTable-email-lbl">
      {data.email}
    </SingleLineBoldCell>,
    <strong key="3" data-qa="usersTable-role-lbl">
      {data.roles}
    </strong>,
    <TableActions key="2">
      {user.id.toString() != data.id && (
        <button
          data-qa="usersTable-editRole-btn"
          type="button"
          onClick={() =>
            showModal(EditUser, {
              editUser: data,
              onComplete: () => {
                users.load();
              },
              centres,
            })
          }
          disabled={!isSuperAdmin || loadingCentres}
        >
          <IconEdit responsive />
          <span className="sr-only">Edit {data.name} User</span>
        </button>
      )}
      {users.totalRecords > 1 && user.id.toString() != data.id && (
        <button
          type="button"
          data-qa="usersTable-delete-btn"
          onClick={() =>
            showModal(DeleteData, {
              onConfirmDelete: () => {
                onConfirmDelete(data);
              },
            })
          }
          disabled={!isSuperAdmin}
        >
          <IconDelete responsive />
          <span className="sr-only">Delete {data.name} User</span>
        </button>
      )}
    </TableActions>,
  ];

  const onConfirmDelete = async (user: IUser) => {
    const deleteUser: IDeleteUser = {
      id: user?.id,
      email: user?.email,
    };
    const res = await fetchAPI<string>({
      path: `Users/delete`,
      method: 'DELETE',
      body: deleteUser,
      responseType: 'text',
    });

    if (res.success) {
      window.location.reload();
    }
  };

  const clearAllFilters = () => {
    const url = deleteQueryParams('users', 'userroles', 'page');
    navigate(url || '');
  };

  return (
    <FullWidthContainer>
      <Stack stackMultiplier={2}>
        <GridFlex gutters={false} align="flex-end" justify="space-between">
          <GridFlexItem gutters={false} small={8}>
            <Stack stackMultiplier={1}>
              <h1>Users</h1>
              <p>Add new Enrol users from your organisation.</p>
            </Stack>
          </GridFlexItem>
          <Button
            iconLeft={<IconPlus responsive />}
            onClick={() => {
              showModal(CreateUser, {
                onConfirmCreateUser: () => {
                  window.location.reload();
                },
                centres,
              });
            }}
            disabled={!isSuperAdmin || loadingCentres}
          >
            Create new user
          </Button>
        </GridFlex>
        <FlexGrid column={2} row={1}>
          <UserNameFilter />
          <UserRoleFilter />
          {hasActiveFilters && (
            <ActionLink onClick={clearAllFilters}>Clear all</ActionLink>
          )}
        </FlexGrid>
        <Table
          caption="List of users"
          data={users.data}
          headerItems={[
            { label: 'Name', sortColumnName: 'name' },
            { label: 'Email', sortColumnName: 'email' },
            { label: 'Roles', sortColumnName: 'roles' },
            { label: 'Actions' },
          ]}
          paged={users.pagedParams}
          renderRow={renderRow}
        />
        <Paging pageCount={users.pageCount} />
      </Stack>
    </FullWidthContainer>
  );
};

export default UsersList;
